<template>
    <div>
        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard' }">
                    <feather-icon class="align-text-top" icon="HomeIcon" size="15"/>
                </b-breadcrumb-item>
                <b-breadcrumb-item :to="{ name: PREFIX+'s' }">{{$t('label_menu_pattern')}}</b-breadcrumb-item>
                <b-breadcrumb-item active>{{ action == 'editing'? $t('label_edit') : $t('label_new') }}</b-breadcrumb-item>
            </div>
        </div>
        <b-col cols="12" md="12">
            <b-card no-body class="mb-0">
                <!-- Header -->
                <div class="d-flex  mt-1 px-2 py-1 align-items-center">
                    <h5 class="mb-0">
                        {{ action == 'editing'? $t('label_menu_pattern') : $t('label_menu_pattern') }}
                    </h5>
                    <b-button class="ml-auto" variant="outline-primary"  @click="$bvModal.show(SHORTCODE_PREFIX + '-modal')">
                        {{$t('label_pattern_tags_show')}}
                    </b-button>
                </div>


                <validation-observer
                        #default="{ handleSubmit, invalid }"
                        :ref="MODULE_PREFIX + '_FORM'"
                        tag="form"
                        class="p-2"
                >

                    <validation-provider

                            #default="validationProps"
                            :name="$t('label_deal_number')"
                            rules="required"
                            slim
                    >
                        <b-form-group :label="$t('label_category')"
                        >
                            <infinity-scroll
                            v-model="itemData.id_pattern_category"
                            selected-prop="id"
                            label=""
                            :url="'/' + MODULE_PREFIX + '_category'"
                            :placeholder="$t('label_select')"
                            :default-selection="chosenCategory"
                            >
                            <template #label="{item}">{{item.name}}</template>

                            </infinity-scroll>

                            <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>

                        </b-form-group>
                    </validation-provider>


                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_pattern_name')"
                            rules="required"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_pattern_name')"
                                class="required"
                        >
                            <b-form-input

                                    v-model="itemData.pattern_name"
                                    :placeholder="$t('label_pattern_name')"
                                    :state="getValidationState(validationProps)"
                                    trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            class="mt-2"
                            #default="validationProps"
                            :name="$t('label_logo_attach')"
                            slim
                    >
                        <b-form-group
                                label=""
                        >
                            <b-form-checkbox
                                    id="is-pattern-logo"
                                    value="1"
                                    unchecked-value="0"
                                    v-model="itemData.pattern_is_logo"
                            >
                                <label for="is-pattern-logo">{{ $t('label_logo_attach') }}</label>
                            </b-form-checkbox>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            class="mt-2"
                            #default="validationProps"
                            :name="$t('label_footer_attach')"
                            slim
                    >
                        <b-form-group
                                label=""
                        >
                            <b-form-checkbox
                                    id="is-pattern-footer"
                                    value="1"
                                    unchecked-value="0"
                                    v-model="itemData.pattern_is_footer"
                            >
                                <label for="is-pattern-footer">{{ $t('label_footer_attach') }}</label>
                            </b-form-checkbox>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider

                            #default="validationProps"
                            :name="$t('label_description')"
                            rules="required"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_description')"

                        >
                            <b-form-textarea

                                    :state="getValidationState(validationProps)"
                                    v-model="itemData.pattern_description"
                            ></b-form-textarea>

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            class="mt-2"
                            #default="validationProps"
                            :name="$t('label_content')"
                            rules="required"
                            slim

                    >
                        <b-form-group
                                :label="$t('label_content')"

                        >
                            <quill-editor

                                    required
                                    v-model="itemData.pattern_content"
                                    :options="snowOption"
                            ></quill-editor>

                            <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>

                        </b-form-group>
                    </validation-provider>


                    <div class="d-flex mt-2 justify-content-end">
                        <b-button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)"
                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                  variant="primary"
                                  class="mr-2"
                                  type="submit"
                        >
                            {{ action == 'editing'? $t('label_save') : $t('label_add') }}
                        </b-button>
                        <b-button
                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                type="button"
                                variant="outline-secondary"
                                v-on:click="$router.go(-1)"
                        >
                            <!--@click="hide"-->
                            {{ $t('label_cancel') }}

                        </b-button>
                    </div>

                </validation-observer>

            </b-card>
        </b-col>

        <short-code-modal></short-code-modal>

    </div>
</template>

<script>

    import { quillEditor } from 'vue-quill-editor'
    import Quill from 'quill'
    // import ImageResize from'quill-image-resize-module'
    // Quill.register('modules/imageResize', ImageResize);
    let Font = Quill.import('formats/font');
    // console.log(Font);
    let fontAliases = [ 'arial', 'comic_sans', 'courier', 'georgia', 'lucida_sans', 'tahoma', 'times_new_roman', 'trebuchet', 'verdana'];
    Font.whitelist = fontAliases;
    Quill.register(Font, true);


    let toolbarOptions = [
        [{ 'font': fontAliases }],
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        // [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean']                                         // remove formatting button
    ];

    // var toolbar =  Quill.get('toolbar');
    // console.log('toolbar',toolbar);
    // toolbar.addHandler('omega', function() {
    //     console.log('omega')
    // });
    //
    //
    // var customButton = document.querySelector('.ql-omega');
    // customButton.addEventListener('click', function() {
    //     var range = quill.getSelection();
    //     if (range) {
    //         quill.insertText(range.index, "Ω");
    //     }
    // });
    import {ValidationProvider, ValidationObserver} from 'vee-validate'

    import {
        BSidebar, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BCard, BFormTextarea, BFormCheckbox
    } from 'bootstrap-vue'
    import {MODULE_PREFIX, MODULE_PREFIX as PREFIX, SHORTCODE_PREFIX} from './moduleHelper'
    import vSelect from 'vue-select'
    import  infinityScroll from '@/views/components/infinityScroll'
    import  shortCodeModal from './modal/shortCodeModal'

    export default {
        name: "editItem.vue",
        components: {
            // Feather,
            BSidebar,
            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BButton, BCard,
            vSelect,
            BFormCheckbox,
            BFormTextarea,
            // Form Validation
            ValidationProvider,
            ValidationObserver,
            infinityScroll,
            quillEditor,
            shortCodeModal
        },
        data() {
            return {

                PREFIX,
                MODULE_PREFIX,
                SHORTCODE_PREFIX,

                itemData: {},

                action: 'adding',

                snowOption: {
                    theme: 'snow',
                    modules: {
                        toolbar: toolbarOptions
                    },
                },
                // snowOption: {
                //     theme: 'snow',
                //     formats: ['image', 'align', 'width'], // allowed tags
                //     modules: {
                //         toolbar: [ [{ 'align': [] }]],
                //         // imageFormat: {},
                //         imageResize: {//Add
                //             displayStyles: {//Add
                //                 backgroundColor: 'black',
                //                 border: 'none',
                //                 color: 'white'
                //             },
                //         }
                //     }
                // },

                blankItemData: {

                    id_pattern:null,
                    id_pattern_category: null,
                    pattern_name: '',
                    pattern_is_logo: 0,
                    pattern_is_footer: 0,
                    pattern_description: '',
                    pattern_content: '',

                },
                chosenCategory: false
            }
        },
        created() {

            this.blankItemData.id = this.$router.currentRoute.params.id;

            this.initData();

        },
        methods: {

            initData() {

                if (this.blankItemData.id > 0) {
                    this.action = 'editing';
                    this.async('get', '/' + this.MODULE_PREFIX + '/' + this.blankItemData.id, {}, function (resp) {

                        let editedItem = resp.data;
                        let item = Object.assign({}, this.blankItemData);
                        item.id_pattern = editedItem.id_pattern;
                        for (let prop in item) {
                            if (editedItem.hasOwnProperty(prop)) {
                                item[prop] = editedItem[prop];
                            }
                        }
                        if(editedItem.category){
                            this.chosenCategory = editedItem.category;
                        }
                        this.itemData = item;
                    });


                } else {
                    this.action = 'adding';
                    this.itemData = Object.assign({}, this.blankItemData);

                }


            },
            onSubmit() {
                if (this.action == 'adding') {
                    this.async('post', '/' + this.MODULE_PREFIX, this.itemData, function (resp) {
                        // this.$emit('item-added',{});
                        // this.$bvModal.hide(this.PREFIX + '-modal');
                        this.$router.push({name: this.MODULE_PREFIX + 's'});

                    });
                } else {


                    this.async('put', '/' + this.MODULE_PREFIX + '/' + this.itemData.id, this.itemData, function (resp) {
                        // this.$emit('item-edited',{});
                        // this.$bvModal.hide(this.PREFIX + '-modal');
                        this.$router.push({name: this.MODULE_PREFIX + 's'});
                    });
                }
            },

        }
    }
</script>

<style lang="scss"> //!!! for editor
@import '@core/scss/vue/libs/quill.scss';
.ql-editor{
    min-height: 250px;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='arial']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='arial']::before
{
    content: 'Arial';
    font-family: 'Arial';
}
.ql-font-arial {
    font-family: 'Arial';
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='comic_sans']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='comic_sans']::before
{
    content: 'Comic Sans';
    font-family: 'Comic Sans', 'Comic Sans MS';
}
.ql-font-comic_sans {
    font-family: 'Comic Sans', 'Comic Sans MS';
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='courier']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='courier']::before
{
    content: 'Courier New';
    font-family: 'Courier New', 'Courier';
}
.ql-font-courier {
    font-family: 'Courier New', 'Courier';
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='georgia']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='georgia']::before
{
    content: 'Georgia';
    font-family: 'Georgia';
}
.ql-font-georgia {
    font-family: 'Georgia';
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='lucida_sans']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='lucida_sans']::before
{
    content: 'Lucida Sans  Unicode';
    font-family: 'Lucida Sans Unicode', 'Lucida Sans';
}
.ql-font-lucida_sans {
    font-family: 'Lucida Sans Unicode', 'Lucida Sans';
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='tahoma']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='tahoma']::before
{
    content: 'Tahoma';
    font-family: 'Tahoma';
}
.ql-font-tahoma {
    font-family: 'Tahoma';
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='times_new_roman']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='times_new_roman']::before
{
    content: 'Times New Roman';
    font-family: 'Times New Roman';
}
.ql-font-times_new_roman {
    font-family: 'Times New Roman';
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='trebuchet']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='trebuchet']::before
{
    content: 'Trebuchet MS';
    font-family: 'Trebuchet MS', 'Trebuchet';
}
.ql-font-trebuchet {
    font-family: 'Trebuchet MS', 'Trebuchet';
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='verdana']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='verdana']::before
{
    content: 'Verdana';
    font-family: 'Verdana';
}
.ql-font-verdana {
    font-family: 'Verdana';
}

/****************************************************
Set the font-family content used for the HTML content.
*****************************************************/

.ql-editor{
    font-family: 'Arial';
}

.ql-toolbar.ql-snow .ql-picker-label,
.ql-toolbar .ql-formats .ql-picker-label.ql-active, .ql-toolbar .ql-formats button.ql-active{
    overflow: hidden;
}

</style>
